// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';

// Gatsby
import { graphql, useStaticQuery } from 'gatsby';

// Packages
import { useGlobal } from 'reactn';
import s from 'src/styles';

// Utilities
import { localize, localizeLink } from 'utilities';
import { TransitionLink } from 'transition';

// Components
import Tile from 'components/tile';
import { ReactComponent as SvgLogo } from 'src/images/coffee_collective.svg';

const FooterComponent = props => {
    const [theme] = useGlobal('pageTheme');

    const { content, pageContext, location } = props;

    // Labels
    const { labels, locale } = pageContext;

    // Cookies
    const [, setCookieDecided] = useGlobal('cookieDecided');

    // Some pages only exist in english
    const disabled = location.pathname.indexOf('/stories') > -1;

    const disabledFooter =
        ['allProducts', 'subscription'].indexOf(pageContext.templateName) > -1;

    return !disabledFooter ? (
        <>
            <FooterWrapper {...{ theme }}>
                <Footer>
                    <TileWrapper>
                        <Tile
                            {...{
                                fill: theme.text(),
                                background: theme.tile(),
                            }}
                        />
                    </TileWrapper>
                    <InfoWrapper>
                        <InfoSection>
                            <h6>{labels.PARTIALS.FOOTER.ADDRESS}</h6>
                            <p>
                                {content.address
                                    .split('\n')
                                    .map((item, key) => {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                            </p>
                        </InfoSection>
                        <InfoSection>
                            <h6>{labels.PARTIALS.FOOTER.CONTACT}</h6>
                            <p>
                                <a href={`mailto:${content.email}`}>
                                    {content.email}
                                </a>
                                <br />
                                {content.phone}
                            </p>
                        </InfoSection>
                        <InfoSectionNarrow>
                            <h6>{labels.PARTIALS.FOOTER.FOLLOW}</h6>
                            <p>
                                {content.some.map((item, key) => {
                                    return (
                                        <span key={key}>
                                            <a
                                                href={item.link}
                                                target="_blank"
                                                title={item.name}
                                                rel="noopener noreferrer">
                                                {item.name}
                                            </a>
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </InfoSectionNarrow>
                        <InfoSectionNarrow />
                        <InfoSectionNarrow {...{ disabled }}>
                            <h6>{labels.PARTIALS.FOOTER.LANGUAGE}</h6>
                            <p>
                                <StateSpan {...{ active: locale === 'en' }}>
                                    <TransitionLink
                                        disabled={disabled}
                                        from={location}
                                        to={localizeLink(
                                            location.pathname.replace(
                                                '/da',
                                                ''
                                            ),
                                            'en'
                                        )}
                                        state={{ transition: 'default' }}>
                                        {labels.LANGUAGE.EN}
                                    </TransitionLink>
                                    <br />
                                </StateSpan>
                                <StateSpan {...{ active: locale === 'da' }}>
                                    <TransitionLink
                                        disabled={disabled}
                                        from={location}
                                        to={localizeLink(
                                            location.pathname,
                                            'da'
                                        )}
                                        state={{ transition: 'default' }}>
                                        {labels.LANGUAGE.DA}
                                    </TransitionLink>
                                </StateSpan>
                            </p>
                        </InfoSectionNarrow>
                    </InfoWrapper>
                    <Logo {...{ theme }}>
                        <SvgLogo />
                        <DetMedSmaat>
                            <button onClick={() => setCookieDecided(false)}>
                                {labels.PARTIALS.FOOTER.COOKIES}
                                <br />
                            </button>
                            {content.otherLinks.map((item, key) => {
                                return item.__typename === 'DatoCmsBlogpost' ? (
                                    <span key={key}>
                                        <TransitionLink
                                            from={location}
                                            title={item.blogpostLinkTitle}
                                            to={`/${content.stories.slug}/${
                                                item.blogpost.slug
                                            }`}>
                                            {item.blogpostLinkTitle}
                                        </TransitionLink>
                                        <br />
                                    </span>
                                ) : (
                                    <span key={key}>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            title={item.name}
                                            rel="noopener noreferrer">
                                            {item.name}
                                        </a>
                                        <br />
                                    </span>
                                );
                            })}
                            <ImageLinks {...{ theme }}>
                                {content.logoLinks.map((item, key) => (
                                    <a
                                        href={item.link}
                                        key={key}
                                        target="_blank"
                                        title={item.name}
                                        rel="noopener noreferrer"
                                        dangerouslySetInnerHTML={{
                                            __html: item.svg,
                                        }}
                                    />
                                ))}
                            </ImageLinks>
                        </DetMedSmaat>
                    </Logo>
                </Footer>
            </FooterWrapper>
        </>
    ) : null;
};

const FooterWrapper = styled.div`
    ${props => s.layout.fullWidthBackground(props.theme.tile())};
    display: block;
    color: ${props => props.theme.text()};
    display: flex;
    justify-content: center;
`;

const Footer = styled.div`
    ${s.grid.contentWidth};

    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${s.responsive.property('padding-top', {
        0: 30,
        10: 50,
        20: 80,
    })};

    ${s.responsive.property('padding-bottom', {
        0: 30,
        10: 50,
        20: 60,
    })};
`;

const DetMedSmaat = styled.div`
    ${s.responsive.property('margin-top', {
        0: '1em',
        30: '0em',
    })};

    ${s.responsive.property('flex-direction', {
        0: 'column',
        20: 'row',
    })};

    ${s.responsive.property('align-items', {
        0: 'flex-start',
        20: 'flex-end',
    })};

    display: flex;

    span,
    button {
        cursor: pointer;
        background: none;
        padding: 0;
        border: none;
        ${s.fonts.style('h18')};
        opacity: 0.5;
        transition: opacity 400ms;
        &:hover {
            opacity: 1;
        }
    }

    span + span,
    button + span {
        ${s.responsive.property('margin-left', {
            0: 0,
            20: '2em',
        })};

        ${s.responsive.property('margin-top', {
            0: '1em',
            20: 0,
        })};
    }
`;

const TileWrapper = styled.div`
    width: 100%;

    ${s.fonts.size(12)};

    margin-bottom: 50px;
`;

const ImageLinks = styled.div`
    display: flex;

    ${s.responsive.property('margin-top', {
        0: '2em',
        20: 0,
    })};

    ${s.grid.gutter('margin-left', {
        0: 0,
        20: 2,
    })};

    a {
        position: relative;
        display: flex;
        align-items: flex-end;
    }
    a + a {
        ${s.grid.gutter('margin-left', {
            0: 1,
        })};
    }
    svg {
        height: 50px;
        min-width: 25px;
        path,
        rect {
            fill: ${props => props.theme.text()};
        }
    }
`;

const Logo = styled.div`
    ${s.responsive.property('padding-top', {
        0: 50,
        20: 80,
    })};

    display: flex;

    flex-direction: column;

    ${s.responsive.mq(30)} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-items: flex-end;
    }

    > svg {
        ${s.responsive.property('width', {
            0: 150,
            10: 200,
            20: 250,
        })};

        ${s.responsive.property('height', {
            0: 15,
            10: 20,
            20: 25,
        })};

        path {
            fill: ${props => props.theme.text()};
        }
    }
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const InfoSection = styled.div`
    ${s.grid.columns('width', {
        0: 2,
        20: 3,
    })};

    ${s.responsive.property('margin-bottom', {
        0: '3em',
        30: '0em',
    })};

    &:nth-of-type(2) {
        ${s.grid.gutter('margin-left', {
            0: 2,
        })};
    }

    h6 {
        ${s.fonts.style('h17')};
    }

    p {
        ${s.fonts.style('h15')};
        span {
            ${s.fonts.style('h15')};
        }
    }
`;

const InfoSectionNarrow = styled.div`
    ${s.grid.columns('width', {
        0: 1.333,
        20: 2,
        30: 2,
    })};

    ${s.responsive.property('margin-bottom', {
        0: '3em',
        30: '0em',
    })};

    &:nth-of-type(3) {
        ${s.grid.gutter('margin-left', {
            30: 2,
        })};
    }

    &:nth-of-type(4),
    &:nth-of-type(5) {
        ${s.grid.gutter('margin-left', {
            0: 2,
        })};
    }

    h6 {
        ${s.fonts.style('h17')};
    }

    p {
        ${s.fonts.style('h15')};
        span {
            ${s.fonts.style('h15')};
            &[role='button'] {
                cursor: pointer;
            }
        }
    }

    ${props =>
        props.disabled &&
        css`
            opacity: 0.3;
        `};
`;

const StateSpan = styled.span`
    opacity: 0.5;
    transition: opacity 400ms;
    &:hover {
        opacity: 1;
    }
    ${props =>
        props.active &&
        css`
            opacity: 1;
        `};
`;

export default props => {
    const { locale } = props.pageContext;
    const {
        allDatoCmsPartialFooter,
        datoCmsPageStory,
    } = useStaticQuery(graphql`
        query {
            datoCmsPageStory {
                slug
            }
            allDatoCmsPartialFooter {
                edges {
                    node {
                        locale
                        address
                        email
                        phone
                        otherLinks {
                            __typename
                            ... on DatoCmsBlogpost {
                                blogpostLinkTitle
                                blogpost {
                                    slug
                                }
                            }
                            ... on DatoCmsExternalLink {
                                name
                                link
                            }
                        }
                        some {
                            name
                            link
                        }
                        logoLinks {
                            name
                            link
                            svg
                        }
                    }
                }
            }
        }
    `);

    return (
        <FooterComponent
            {...{
                content: {
                    ...localize(allDatoCmsPartialFooter, locale),
                    stories: datoCmsPageStory,
                },
                ...props,
            }}
        />
    );
};
